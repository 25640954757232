import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoPinMarker16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M7.253 1.266a5.705 5.705 0 0 0-1.98.657c-1.844 1.02-2.982 3.036-2.89 5.117.087 1.943 1.011 3.877 2.825 5.91.431.483 1.242 1.276 1.445 1.414.72.488 1.64.555 2.4.175.264-.132.461-.291.931-.751 3.315-3.249 4.421-6.609 3.086-9.375a5.272 5.272 0 0 0-.999-1.445c-.846-.888-1.9-1.45-3.138-1.675-.344-.062-1.328-.078-1.68-.027M8.66 2.521a4.297 4.297 0 0 1 2.46 1.266c1.044 1.062 1.454 2.518 1.143 4.066-.26 1.296-1.079 2.766-2.336 4.193-.343.39-1.175 1.211-1.326 1.31a1.14 1.14 0 0 1-1.202.001c-.166-.107-1.085-1.027-1.436-1.437-2.089-2.439-2.787-4.716-2.039-6.653a4.33 4.33 0 0 1 .955-1.479c.695-.706 1.485-1.109 2.508-1.277.248-.041.987-.035 1.273.01m-.967 1.932a2.436 2.436 0 0 0-1.899 1.41 2.504 2.504 0 0 0-.129 1.562A2.412 2.412 0 0 0 6.943 9c.378.184.6.234 1.044.236.395.002.535-.023.896-.158a2.452 2.452 0 0 0 1.286-1.198c.169-.368.23-.645.23-1.04s-.061-.672-.23-1.04c-.24-.524-.765-1.007-1.316-1.211a2.694 2.694 0 0 0-1.16-.136m.521 1.254c.31.059.586.248.759.52.126.197.174.365.174.613s-.048.416-.174.613a1.15 1.15 0 0 1-1.267.507 1.198 1.198 0 0 1-.749-.62c-.088-.172-.09-.186-.09-.5 0-.313.002-.328.089-.498a1.156 1.156 0 0 1 1.258-.635"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoPinMarker16;
